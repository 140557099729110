<template>
    <div 
        class="row"
        :class="{
            'row--large': large,
            'row--full': full,
            'row--space-evenly': spaceEvenly,
            'row--stack-mobile': stackMobile,
            'row--flex-start': alignTop,
            'row--padding': padding,
            'row--max-width': fullWidth,
            'row--wrap': rowWrap
        }"
    >
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        large: {
            type: Boolean,
            default: false,
        },

        full: {
            type: Boolean,
            default: false,
        },

        spaceEvenly: {
            type: Boolean,
            default: false
        },

        stackMobile: {
            type: Boolean,
            default: false
        },

        alignTop: {
            type: Boolean,
            default: false
        },

        padding: {
            type: Boolean,
            default: false
        },

        fullWidth: {
            type: Boolean,
            default: false
        },

        rowWrap: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.row {
    max-width: 1200px;
    margin: 0px auto;
    width: calc(100% - 40px);
    //height: 100%;
    padding: 0 rem(20);
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &--wrap {
        flex-wrap: wrap;
    }

    &--max-width {
        max-width: 100%;
    }

    &--padding {
        padding: rem(20);
    }

    &--large {
        height: 70%;

        @media(max-width: $mobile-bp) {
            height: 100%;
        }
    }

    &--full {
        height: 100%;
    }

    &--space-evenly {
        justify-content: space-evenly;
    }

    &--flex-start {
        justify-content: flex-start;
    }

   &--stack-mobile {
        @media(max-width: $mobile-bp) {
            flex-direction: column;
        }
   }
    
}
</style>