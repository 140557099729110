var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row",class:{
        'row--large': _vm.large,
        'row--full': _vm.full,
        'row--space-evenly': _vm.spaceEvenly,
        'row--stack-mobile': _vm.stackMobile,
        'row--flex-start': _vm.alignTop,
        'row--padding': _vm.padding,
        'row--max-width': _vm.fullWidth,
        'row--wrap': _vm.rowWrap
    }},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }